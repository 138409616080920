import React, {Component} from 'react'
import Header from "../Component/Header";
import Routes from '../Component/Routes';
import Footer from "../Component/Footer";

class App extends Component {

    render() {
        return (
            <React.Fragment>
                <Header/>
                <Routes/>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default App;
