import {API} from "../config";
import React, {Component, useState} from "react";
import axios from 'axios';
import { isAuthenticate } from "../Core/apiCore";

export default class Admin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            candidatures: [],
        }
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/candidature/list`)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        candidatures: response.data.data
                    });
                }
            });
    }

    render() {
        return (
            <div className="container">
                <h2>
                    Candidatures
                </h2>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Nom</th>
                        <th scope="col">Prénom</th>
                        <th scope="col">CV</th>
                        <th scope="col">Message</th>
                        <th scope="col">Email</th>
                        <th scope="col">Date d'envoi</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col">Fonction</th>
                        <th scope="col">Expérience</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.candidatures.map((candidate, index) => {
                        return (
                            <tr key={index}>
                                <td>{candidate.name}</td>
                                <td>{candidate.firstname}</td>
                                <td>
                                    <a href={`${process.env.REACT_APP_API_URL}/image/cv/${candidate.id}`} target="_blank">Telecharger le CV</a>
                                </td>
                                <td>{candidate.message}</td>
                                <td>{candidate.email}</td>
                                <td>{candidate.created_at}</td>
                                <td>{candidate.phone}</td>
                                <td>{candidate.jobFunction}</td>
                                <td>{candidate.experience}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}