import React, { Component } from "react";
import 'datejs';
import FormCandidature from "./FormCandidature";

class ContactForm extends Component {

    constructor(props) {
        super(props);
    }

  render() {

    return (
        <div>
            <FormCandidature email={this.props.email} />
        </div>
    )
  }
}

export default ContactForm;
