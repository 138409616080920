import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { loadJobOffer } from "../Core/apiCore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarker} from "@fortawesome/free-solid-svg-icons";

export default class JobOffer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            jobOffer: {},
            error: false,
            isLoad: false,
        }
    }

    componentDidMount() {
        this.loadJob(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.loadJob(this.props.match.params.id);
        }
    }

    loadJob = (idJob) => {
        loadJobOffer(idJob).then((data) => {
            this.setState({
                jobOffer: data.data,
                isLoad: true
            })
        }).catch((e) => {
            this.setState({
                error: e,
                isLoad: true
            })
        })
    };

    templateRender = () => {
        const { isLoad, jobOffer } = this.state;

        if (!isLoad) {
            return (
                <div className="col col-md-12">
                    <h3>
                        Chargement...
                    </h3>
                </div>
            )
        } else if (jobOffer.length === 0) {
            return (
                <div className="col col-md-12">
                    <h3>
                        Aucun résutat
                    </h3>
                </div>
            )
        } else {
            return (
                <div className="topmargin bottommargin">

                    <h2 className="center">{jobOffer.position}</h2>
                    <h4 className="card-subtitle mb-2 text-muted"><FontAwesomeIcon icon={faMapMarker} /> {jobOffer.location}</h4>

                    <div className= "topmargin">
                        <h5>Vos missions: </h5>
                        <p className="card-text">{jobOffer.mission_description}</p>
                    </div>

                    <div className="topmargin">
                        <h5>Votre profil: </h5>
                        <div dangerouslySetInnerHTML={ {__html: jobOffer.profile_description} } />
                    </div>

                    {jobOffer.reference && <div className= "topmargin">
                        <h5>Reference: </h5>
                        <p className="card-text">{jobOffer.reference}</p>
                    </div>}
                </div>
            )
        }
    };


    render() {
        return (
            <div className="container">
                <div className="row">
                    { this.templateRender() }
                </div>

                <div className="row bottommargin">
                    <Link to="/candidature" data-toggle="modal" data-target="#contactFormModal" className="button
                     button-3d nomargin btn-block button-xlarge d-none d-md-block center">
                        Postulez maintenant
                    </Link>
                </div>
            </div>
        )
    }
}