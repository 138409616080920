import React from "react";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = () => {
        if (typeof window == 'undefined') {
            return false;
        }
        if (localStorage.getItem('authToken')) {
            return JSON.parse(localStorage.getItem('authToken'));
        }
        else {
            return false;
        }
    };

    return(
        <Route {...rest} render={ props => isAuthenticated() ? (<Component {...props} />) : <Redirect to="/" /> } />
    );
};