import React, { Component } from 'react';
import '../css/home.css';
import serviceA from "../images/generation-interim/gen_interim_2.png"
import serviceB from "../images/generation-interim/gen_interim_4.png"
import serviceC from "../images/generation-interim/gen_interim_5.png"
// import serviceD from "../images/services/macbook.png"
import serviceTopPage from "../images/generation-interim/haut_de_page.png"
import serviceF from "../images/generation-interim/gen_interim_3.png"
import serviceG from "../images/generation-interim/gen_interim_12.png"
import serviceG13 from "../images/generation-interim/banner_guadeloupe.png"
// import serviceH from "../images/generation-interim/gen_interim_8.png"
// import {Link} from "react-router-dom"
import $ from 'jquery';
import { Slide } from 'react-slideshow-image';

class Home extends Component {

  constructor(props) {
    super(props);

    this.properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      autoplay: true,
      indicators: true,
      arrows: false
    };
  }

 componentDidMount()
 {
   $.ajax({
     url: 'js/plugins.js',
     dataType: 'script',
     async: true,
     success: function () {
       $.ajax({
         url: 'js/functions.js',
         dataType: 'script',
         async: true
       });
     }
   });
 }

  Slideshow = () => {
    return (
        <div className="slide-container">
          <Slide {...this.properties}>
            <div className="each-slide">
              <div style={{backgroundImage: `url(${serviceTopPage})`, backgroundPosition: "33% 20%", backgroundRepeat: "no-repeat"}}>
                    <span className="text-slide slide-3 leftmargin-lg">

                    </span>
              </div>
            </div>
            <div className="each-slide">
              <div style={{backgroundImage: `url(${serviceG13})`, backgroundPosition: "33% 20%", backgroundRepeat: "no-repeat"}}>
                    <span className="text-slide slide-3 leftmargin-lg">

                    </span>
              </div>
            </div>
          </Slide>
        </div>
    )
  };

  render() {
	return (
		<React.Fragment>
          {this.Slideshow()}

		  <section id="content" style={{marginBottom: "0px"}}>
			<div className="content-wrap">

			  <div className="container clearfix">
				<div className="col_one_third nobottommargin">
				  <div className="feature-box media-box">
					<div className="fbox-media">
					  <img src={serviceA} alt="Why choose Us?" />
					</div>
					<div className="fbox-desc">
					  <h3>Pourquoi travailler <br/> avec nous ?{/*<span className="subtitle">Make our Customers Happy.</span>*/}</h3>
					  <p>Votre réussite sera la nôtre c’est pour cela qu’il est important de vous accompagner
						dans vos ambitions. Que vous soyez une entreprise ou un intérimaire,
						nous répondrons efficacement à vos besoins</p>
					</div>
				  </div>
				</div>

				<div className="col_one_third nobottommargin">
				  <div className="feature-box media-box">
					<div className="fbox-media">
					  <img src={serviceB} alt="Why choose Us?" />
					</div>
					<div className="fbox-desc">
					  <h3>A quoi s'attendre ?</h3>
					  <p>Nous couvrons vos besoins de personnels en cas d'accroissement d'activité,
						départ en congés... Nous vous assistons dans le recrutement de profils compétents.</p>
					</div>
				  </div>
				</div>

				<div className="col_one_third nobottommargin col_last">
				  <div className="feature-box media-box">
					<div className="fbox-media">
					  <img src={serviceC} alt="Why choose Us?" />
					</div>
					<div className="fbox-desc">
					  <h3>Notre Mission{/*<span className="subtitle">Make our Customers Happy.</span>*/}</h3>
					  <p>Le but étant de vous proposé un suivi sur mesure. Que ce soit pour un candidat ou pour une entreprise,
						notre mission est de vous accompagné vers vos objectifs.</p>
					</div>
				  </div>
				</div>

			  </div>

			  <div className="section">

				<div className="container clearfix">

				  <div className="heading-block center">
					<h2>La réactivité et la rigueur de notre équipe vous garantie :</h2>
					{/*<span>Choose from a wide array of Options for your best matched Customizations</span>*/}
				  </div>

				  <div className="col_full">

					<img data-animate="fadeIn" className="aligncenter fadeIn animated" src={serviceF}
						 alt="Macbook" />

				  </div>

				  <div className="col_one_third nobottommargin">

					<div className="feature-box fbox-plain">
					  <div className="fbox-icon">
						<a href="#"><i className="i-alt">1.</i></a>
					  </div>
					  <h3>Gain de temps</h3>
					  <p>Un champ d’investigation plus large avec l’utilisation d’outils spécifiques
						au recrutement.L’agence permet une meilleure évaluation des candidatures grâce à son professionnalisme et des séries de test.
					  </p>
					</div>

				  </div>

				  <div className="col_one_third nobottommargin">

					<div className="feature-box fbox-plain">
					  <div className="fbox-icon">
						<a href="#"><i className="i-alt">2.</i></a>
					  </div>
					  <h3>Expertise</h3>
					  <p>Bénéficier de l'expertise d'une équipe multi-compétences.
						Nos collaborateurs sont spécilaisés dans différents domaines comme le E-Marketing, l'informatique,le BTP.</p>
					</div>

				  </div>

				  <div className="col_one_third nobottommargin col_last">

					<div className="feature-box fbox-plain">
					  <div className="fbox-icon">
						<a href="#"><i className="i-alt">3.</i></a>
					  </div>
					  <h3>Compétitivité</h3>
					  <p>Offre attractive et transparente, liée à notre indépendance et en adéquation avec la prestation souhaitée.
						Recherche et sélection de personnel intérim CDD CDI ou gestion administrative du personnel recruté par vos soins
					  </p>
					</div>

				  </div>

				</div>

			  </div>

			  <div className="section parallax bottommargin-lg skrollable skrollable-before citation-home"
				   data-bottom-top="background-position:0px 300px;"
				   data-top-bottom="background-position:0px -300px;">
				<div className="heading-block center nobottomborder nobottommargin">
				  <h2>"Nous vous assurons un recrutement réussi, nous avons des candidats qualifiés et disponibles"</h2>
				</div>
			  </div>

			  <div className="container clearfix">

				<div className="col_two_fifth nobottommargin" style={{ minHeight: "350px"}}>
				  <div className="chartjs-size-monitor"
					   style={{position: "absolute", left: "0px", top: "0px", right: "0px",
						 bottom: "0px", overflow: "hidden", pointerEvents: "none",
						 visibility: "hidden", zIndex: "-1"}}>
					<div className="chartjs-size-monitor-expand"
						 style={{ position: "absolute", left: "0", top: "0",
						   right:"0", bottom:"0", overflow:"hidden",
						   pointerEvents: "none", visibility: "hidden", zIndex: "-1"}}>
					  <div style={{position: "absolute", width: "1000000px",
						height: "1000000px", left: "0", top: "0"}}></div>
					</div>
					<div className="chartjs-size-monitor-shrink"
						 style={{ position: "absolute", left: "0", top: "0",
						   right: "0", bottom:"0", overflow: "hidden",
						   pointerEvents: "none", visibility: "hidden", zIndex: "-1"}}>
					  <div style={{ position: "absolute", width: "200%",
						height: "200%", left: "0", top: "0"}}></div>
					</div>
				  </div>
				  <img src={serviceG} alt=""/>
				</div>

				<div className="col_three_fifth nobottommargin col_last">

				  <div className="heading-block">
					<h3>Nos agences</h3>
				  </div>

				  <p>Les agences Génération Intérim Guadeloupe et Martinique
					sont en mesure de répondre à l’ensemble de vos besoins en
					terme de détachement de personnel intérimaire.
				  </p>

				  <p>Vos demandes seront traitées de manière rapide et efficace.
					En effet notre fichier comporte un large vivier de
					candidat avec des compétences sûres qui constitueront, un atout pour votre structure.</p>

				</div>
			  </div>
			  {/*<a href="#" className="button button-full center tright topmargin footer-stick">
				  <div className="container clearfix">
					Need help with your Account? <strong>Start here</strong>
					<i className="icon-caret-right" style={{ top:"4px" }}></i>
				  </div>
				</a>*/}
			</div>
		  </section>
		</React.Fragment>
	);
  }
}

export default Home;
