import React, {useState} from "react";
import { Card, Logo, Form, Input, Button, Error } from './AuthForm';
import {useAuth} from "../Context/auth";
import { signin } from './../Core/apiCore'
import { API } from '../config';
import axios from 'axios';
import { Redirect } from "react-router-dom";

const Login = () => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { setAuthToken } = useAuth();

    if (isLoggedIn) {
        return <Redirect to="/admin"/>
    }

    const postLogin = (e) => {
        e.preventDefault();

        axios.post(`${API}/login`, {
            email: email,
            password: password
        }).then((result) => {
            if (result.status === 200) {
                setAuthToken(result.data);
                setLoggedIn(true);
            } else {
                setError(true);
            }
        }).catch((e) => {
            setError(true);
        })
    };

    return (
            <Card id="loginForm">
                <Logo src="" />

                <h1> Connectez-vous </h1>

                <div className="mb-4">
                    { isError && <Error>L'email ou le mot de passe est incorrect</Error> }
                </div>

                <Form>
                    <Input type="email" placeholder="email" value={email} onChange={(e) => {
                        setEmail(e.target.value);
                    }} />
                    <Input type="password" placeholder="password" value={password} onChange={(e) => {
                        setPassword(e.target.value);
                    }} />
                    <Button onClick={(e) => { postLogin(e) } }>Connexion</Button>
                </Form>
            </Card>


    );
};

export default Login;