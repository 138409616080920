import React, { Component } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import { faMapMarker, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { loadJobOfferList, getCategories } from '../Core/apiCore';
import Tabs from "../Component/Tabs/Tabs";

export default class JobOffers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			jobOffers: [],
			jobOfferCategories: [],
			error: null,
			isLoaded: false,
			isCategoriesLoaded: false,
			limit: 500,
			skip: 0,
			size: 0
		};
	}

	componentDidMount() {
		this.loadJobOffers();
	}

	loadJobOffers = () => {
		loadJobOfferList(this.state.limit, this.state.skip).then((data) => {
			this.setState({
				isLoaded: true,
				jobOffers: data.data,
				size: data.data.length
			});
		}).catch(e => {
			this.setState({
				isLoaded: true,
				error: e
			});
		});

		getCategories().then((data) => {
			this.setState({
				isCategoriesLoaded: true,
				jobOfferCategories: data.data
			});
		}).catch(e => {
			this.setState({
				isCategoriesLoaded: true,
				error: e
			});
		});
	};

	loadMore = () => {
		let skip = this.state.skip + this.state.limit;

		loadJobOfferList(this.state.limit, skip).then((data) => {
			this.setState({
				isLoaded: true,
				jobOffers: [...this.state.jobOffers, ...data.data],
				skip: skip,
				size: this.state.size + data.data.length
			});
		}).catch(e => {
			this.setState({
				isLoaded: true,
				error: e
			});
		});
	};

	loadMoreButton = () => {
		return (
			this.state.jobOffers.length > 0 && this.state.size > 0 && this.state.size >= this.state.limit && (
				<button onClick={() => { this.loadMore() }} className="btn btn-warning mb-5 center">Charger plus</button>
			)
		)
	};

	templateRender = () => {
		const { isLoaded, isCategoriesLoaded, jobOffers, jobOfferCategories } = this.state;
		const catIndex = jobOfferCategories.findIndex(cat => cat.category === '');
		const searchItem = jobOfferCategories[catIndex];
		jobOfferCategories.splice(catIndex, 1);
		jobOfferCategories.push(searchItem);

		if (!isLoaded) {
			return (
				<div className="col col-md-12">
					<h3>
						Chargement...
					</h3>
				</div>
			)
		} else if (jobOffers.length === 0) {
			return (
				<div className="col col-md-12">
					<h3>
						Aucun résutat
					</h3>
				</div>
			)
		} else if (jobOfferCategories.length === 0 && isCategoriesLoaded) {
			return (
				<div className="col col-md-12">
					<h3>
						Aucune categorie
					</h3>
				</div>
			)
		} else {
			return (
				<div>
					<Tabs children={jobOfferCategories}>
						{jobOfferCategories.map((val, k) => {
							let category = val.category === '' ? 'Autres' : val.category;
							return (<div key={k} label={category}>
										{jobOffers.map((elem, key) => {
											if (elem.category === val.category) {
												return (
													<Link to={`/offre-emploi/${elem.id}`} key={key}
														  className="list-group-item list-group-item-action flex-column align-items-start">
														<div className="list-group-item card border-0">
															<div className="card-body">
																<h5 className="card-title">
																	{elem.position}
																</h5>
																<h6 className="card-subtitle mb-2 text-muted preview-infos">
																	<div>
																		<FontAwesomeIcon icon={faMapMarker}/>
																		<span style={{ padding: "10px" }}>{elem.location}</span>
																	</div>

																	<div>
																		<FontAwesomeIcon icon={faCalendarAlt}/>
																		<span style={{ padding: "10px" }}>{moment(elem.publication_date).format('DD-MM-YYYY')}</span>
																	</div>
																</h6>
																<p className="card-text">{elem.mission_description.substring(0, 200) + '...'}</p>
															</div>
														</div>
													</Link>
												)
											}
										})}
									</div>
							)
						})}
					</Tabs>
				</div>
			)
		}
	};

	render() {
		return (
			<div className="container topmargin bottommargin">
				<h1 className="center">Nos offres d'emploi</h1>
				<div className="list-group">
					{this.templateRender()}

					<div className="topmargin">
						{this.loadMoreButton()}
					</div>
				</div>
			</div>
	);
	}
}
