import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ContactForm from './ContactForm'


class Candidature extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isModalShow: false,
      email: 'generation.interim@orange.fr'
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
      this.setShow(false)
  }

  setShow(state, email = 'generation.interim@orange.fr') {
    this.setState({
      isModalShow: state,
      email: email
    });
  }

  render() {
    return (
      <div>
        <section id="page-title">

          <div className="container clearfix">
            <h1>Candidature Spontanée</h1>
          </div>

        </section>

        <section id="content" style={{marginBottom: "0px"}}>

          <div className="content-wrap">

            <div className="container clearfix">

              <div className="row">
                <div className="col-lg-6 bottommargin">
                  <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.697664280766!2d-61.00229418486162!3d14.61629108060981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c6aa1a07c6ca6c9%3A0xeb66439f255dec48!2zR8OpbsOpcmF0aW9uIEludMOpcmltIEd1YWRlbG9wdXBl!5e0!3m2!1sfr!2sfr!4v1577887264279!5m2!1sfr!2sfr"
                      width="400" height="400" frameBorder="0" style={{border: "0"}} allowFullScreen="">
                  </iframe>

                  <div className="mt-5" style={{width: '400px', height: '400px'}}>
                    <h3 style={{textAlign: 'left'}}>Generation Interim Guadeloupe</h3>
                    <address>
                      <strong>Adresse siége:</strong><br/>
                      35 rue de la clinique,<br/>
                      les Abymes Guadeloupe 97139<br/>
                    </address>
                    <abbr title="Phone Number"><strong>Téléphone:</strong></abbr>0590 68 40 90<br/>
		            <abbr title="Fax Number"><strong>Fax:</strong></abbr> 0590 68 27 53<br/>
                    <abbr title="Email Address"><strong>Email:</strong></abbr> generation.interim@orange.fr

                    <div className="mt-3">
                      <div style={{width: '38%'}} className="fslider customjs testimonial twitter-scroll twitter-feed mb-3"
                           data-username="envato" data-count="3" data-animation="slide" data-arrows="false">
                        <a href="https://www.facebook.com/generation.interim" target='_blank'>
                          <i className="i-plain i-small color icon-facebook nobottommargin"
                             style={{ marginRight: "15px" }}></i>
                        </a>
                        <i className="i-plain i-small color icon-linkedin nobottommargin"
                           style={{ marginRight: "15px" }}></i>
                        <a href="mailto:generation.interim@orange.fr">
                          <i className="i-plain i-small color icon-mail nobottommargin"
                             style={{ marginRight: "15px" }}></i>
                        </a>
                      </div>
                      <a onClick={() => { this.setShow(true, 'generation.interim@orange.fr'); }}
                         href="#" data-toggle="modal" data-target="#contactFormModal" className="button
                           button-3d nomargin btn-block button-xlarge d-none d-md-block center">
                        Envoyez votre candidature
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div  style={{width: '400px', height: '400px'}}>
                    <h3 style={{textAlign: 'left'}}>Generation Interim Martinique</h3>
                    <address>
                      <strong>Adresse siége:</strong><br/>
                      17 Rue Des Barrières,<br/>
                      Lamentin (Le) 97232<br/>
                    </address>
                    <abbr title="Phone Number"><strong>Téléphone:</strong></abbr>0596 56 37 88<br/>
                    <abbr title="Fax"><strong>Fax:</strong></abbr>0596 72 10 66<br/>
                    <abbr title="Email Address"><strong>Email:</strong></abbr> generation.interim972@gmail.fr

                    <div className="mt-3">
                      <div style={{width: '38%'}} className="fslider customjs testimonial twitter-scroll twitter-feed mb-3"
                           data-username="envato" data-count="2" data-animation="slide" data-arrows="false">
                        <a href="https://www.facebook.com/generation.interim" target='_blank'>
                          <i className="i-plain i-small color icon-facebook nobottommargin"
                             style={{ marginRight: "15px" }}></i>
                        </a>
                        <i className="i-plain i-small color icon-linkedin nobottommargin"
                           style={{ marginRight: "15px" }}></i>
                        <a href="mailto:generation.interim972@gmail.com">
                          <i className="i-plain i-small color icon-mail nobottommargin"
                             style={{ marginRight: "15px" }}></i>
                        </a>
                      </div>
                      <a onClick={() => { this.setShow(true, 'generation.interim972@gmail.fr'); }}
                       href="#" data-toggle="modal" data-target="#contactFormModal" className="button
                       button-3d nomargin btn-block button-xlarge d-none d-md-block center">
                      Envoyez votre candidature
                    </a>
                    </div>
              </div>

                  <iframe
                      className="mt-5"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15322.06382363674!2d-61.5315802!3d16.2453058!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x43967876a4df4155!2sGENERATION%20INTERIM%20GUADELOUPE!5e0!3m2!1sen!2sil!4v1630609152896!5m2!1sen!2sil"
                      width="400" height="400" style={{border: "0"}} allowFullScreen="" loading="lazy">
                  </iframe>

                  <div className="clear"></div>
                </div>

                <Modal
                    show={this.state.isModalShow}
                    onHide={() => { this.setShow(false); }}
                    aria-labelledby="contactFormModalLabel"
                    size="lg"
                    centered
                >
                  <div className="modal-content">
                    <ModalHeader>
                      <h3 className="modal-title" id="contactFormModalLabel">Envoyez nous votre candidature</h3>
                      <button type="button" onClick={ () => { this.setShow(false); }} className="close"
                              data-dismiss="modal" aria-hidden="true">×</button>
                    </ModalHeader>

                    <ModalBody>
                      <div className="contact-widget">
                        <div className="contact-form-result"></div>

                        <ContactForm email={this.state.email} closeModal={this.closeModal} />
                      </div>
                    </ModalBody>
                  </div>
                </Modal>
              </div>
            </div>
          </div>

        </section>
      </div>
    );
  }
}

export default Candidature;

