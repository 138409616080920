import React, {Component} from "react";
import ValidateForm from "../Component/ValidateForm";
import {addCandidature} from "../Core/apiCore";
import {Button} from "react-bootstrap";

class FormCandidature extends Component {

    constructor(props) {
        super(props);

        this.fileInput = React.createRef();

        this.state = {
            isShowExperience: false,
            errors: {},
            data: {}
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFunctionInput = this.handleFunctionInput.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value;

        if (target.type === 'file') {
            this.setState({
                data: {
                    ...this.state.data,
                    [name]: this.fileInput.current.files[0],
                    'content_type_cv': this.fileInput.current.files[0].type
                }
            });
        } else {
            if (target.type === 'checkbox') {
                value = target.checked
            } else {
                value = target.value;
            }

            this.setState({
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
        }
    }

    handleFunctionInput(event) {
        this.handleInputChange(event);
        let value = event.target.value;
        this.setState({
            isShowExperience: value !== ""
        });
    }

    handleSubmit(event) {
        let formData = new FormData();
        let jsonResp = this.state.data;

        for (let key in jsonResp) {
            if (jsonResp.hasOwnProperty(key)) {
                if (jsonResp[key] === "" || key === "isShowExperience") {
                    delete jsonResp[key];
                    continue;
                }
                formData.append(key, jsonResp[key]);
            }
        }

        let date = new Date();
        formData.append('created_at', date.toString("yyyy/MM/dd H:m:s"));

        let validateForm = new ValidateForm();
        if (!validateForm.validateCandidature(jsonResp)) {
            this.setState({
                errors: validateForm.errors
            });
            event.preventDefault();
            return false;
        }

        addCandidature(formData).then(data => {
            if (data) {
                this.props.closeModal();
            }
        });

        event.preventDefault();
    }

    render() {
        const RenderExperience = () => {
            if (this.state.isShowExperience) {
                return (
                    <div className="col_half" id="contactform-experience">
                        <label htmlFor="template-contactform-experience">Années d'expériences </label>
                        <input type="number" id="template-contactform-experience" name="experience"
                               onChange={this.handleInputChange} className="experience sm-form-control" />
                    </div>
                )
            }

            return ("");
        };

        return <div>
            <p className='font-italic'>
                Veuillez cliquer sur le bouton ci dessous et nous renvoyer à cette adresse <a href={"mailto:" + this.props.email}>{this.props.email}</a> la fiche completer avec votre signature.
            </p>
            <Button variant="warning" onClick={() => window.open(`${process.env.REACT_APP_API_URL}/files/information`)}>Telecharger la fiche d'inscription</Button>
        </div>
    }
}

export default FormCandidature;