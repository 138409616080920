import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContext } from './Context/auth';
import './css/bootstrap.css';
import './css/index.css';
import './css/dark.css';
import './css/animate.css';
import './css/font-icons.css';
import './css/magnific-popup.css';
import './css/responsive.css';
import './css/swiper.css';

const Index = () => {
    const [authToken, setAuthToken] = useState();

    const setToken = (data) => {
        const jsonData = JSON.stringify(data);
        localStorage.setItem('authToken', jsonData);
        setAuthToken(jsonData);
    };

    return (
        <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
            <Router>
                <App />
            </Router>
        </AuthContext.Provider>
    );
};

ReactDOM.render(
    <Index/>
  , document.getElementById('root'));
