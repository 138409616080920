import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isAuthenticate, logout } from '../Core/apiCore'

class Menu extends Component {

  next = () => {
    window.location = '/';
  };

  render() {
    return (
        <nav id="primary-menu" className="style-3">
          <ul className="sf-js-enabled">
            <li className="sub-menu">
              <Link to="/" className="sf-with-ul">
              <div>Accueil</div>
            </Link>
            </li>
            <li className="sub-menu">
              <Link to="/offres-emploi" className="sf-with-ul">
                  <div>Offres d'emploi</div>
              </Link>
            </li>
            <li className="sub-menu">
              <Link to="/qui-sommes-nous" className="sf-with-ul">
                <div>Qui Sommes nous ?</div>
              </Link>
            </li>
            <li className="sub-menu">
              <Link to="/candidature" className="sf-with-ul">
                <div>Candidature</div>
              </Link>
            </li>
            <li className="sub-menu">
              <Link to="/client" className="sf-with-ul">
                <div>Client</div>
              </Link>
            </li>
            {/*<li className="sub-menu">
              <Link to="/admin/login" className="sf-with-ul">
                <div>Login</div>
              </Link>
            </li>*/}
            {isAuthenticate() && (
                <li className="sub-menu">
                  <Link to="/admin" className="sf-with-ul">
                    <div>Admin</div>
                  </Link>
                </li>
            )}
            {isAuthenticate() && (
                <li className="sub-menu">
                  <Link to="#" className="sf-with-ul" onClick={ () => { logout(() => this.next() ) } }>
                    <div>Déconnexion</div>
                  </Link>
                </li>
            )}
          </ul>
        </nav>
      );
  }
}

export default Menu;
