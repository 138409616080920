import React from 'react'
import Menu from "../Component/Menu";
import logo from "../images/generation-interim/LOGO_GENERATION_INTERIM.png"

const Header = () => {
  return (
    <header id="header" className="full-header">
      <div id="header-wrap">
        <div className="container clearfix">
          <div id="primary-menu-trigger">
            <i className="icon-reorder"/>
          </div>
          <div id="logo">
            <a href="" className="standard-logo" data-dark-logo="#">
              <img src={logo} alt="Canvas Logo" />
            </a>
            <a href="" className="retina-logo" data-dark-logo="#">
              <img src={logo} alt="Canvas Logo" />
            </a>
          </div>
          <Menu/>
        </div>
      </div>
    </header>
  );
}

export default Header;
