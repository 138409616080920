import React, { Component } from "react";
// import { Link } from "react-router-dom";
import PageNotFound from './../images/generation-interim/404_not_found.jpg';

class NotFound extends Component {

    render() {
        return (
            <div>
                <img src={PageNotFound} style={{width: 400, height: 400, display: 'block', margin: 'auto', position: 'relative' }} alt="" />
            </div>
        );
    }
}

export default NotFound;