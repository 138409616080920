import React, { Component } from 'react';
import { Slide } from 'react-slideshow-image';
import './../css/whoweare.css';
import serviceA from "../images/generation-interim/gen_interim_8.png";
import serviceF from "../images/generation-interim/gen_interim_6.png";
import serviceB from "../images/about/4.jpg";
import serviceC from "../images/about/5.jpg";
import serviceD from "../images/about/6.jpg";
// import serviceE from "../images/about/7.jpg";
// import johanneSablon from "../images/generation-interim/1.JPG";
// import team from "../images/generation-interim/team.JPG";

class WhoWeAre extends Component {
  constructor(props) {
    super(props);

    this.properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: false,
      autoplay: false,
      indicators: true,
      arrows: false,

    };
  }

  Slideshow = () => {
    return (
        <Slide {...this.properties}>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${serviceD})`}}>
              {/*<span className="text-slide slide-1">March Lab la finesse, et la technique <br/>conçu pour vous.
                  <br/>
                  <button className="btn btn-slide-1">Découvrir</button>
              </span>*/}
            </div>
          </div>

          <div className="each-slide">
            <div style={{'backgroundImage': `url(${serviceB})`}}>
              {/*<span className="text-slide slide-2">Découvrez nos montres Longines sélectionné <br/>spécialement pour vous.
                  <br/>
                  <button className="btn btn-slide-2">Découvrir</button>
              </span>*/}
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${serviceC})`}}>
              {/*<span className="text-slide slide-3">Découvrez nos montres Longines sélectionné <br/>spécialement pour vous.
                  <br/>
                  <button className="btn btn-slide-3">Découvrir</button>
              </span>*/}
            </div>
          </div>
        </Slide>
    )
  };

  render() {
    return (
        <div>
          <div className="section" style={{marginTop: "0px"}}>
            <div className="container">

              <div className="col_full">

                <div className="heading-block center nobottomborder">
                  <h2>Génération Intérim</h2>
                  <span>S’engager avec Génération Intérim, c'est la garantie d'un service sur mesure.</span>
                </div>

                {this.Slideshow()}

              </div>

              <div className="heading-block topmargin-lg center">
                <h2>Historique</h2>
              </div>

              <div className="row bottommargin-sm">

                <div className="col-lg-4 col-md-6 bottommargin">

                  <div className="feature-box fbox-right topmargin fadeIn animated" data-animate="fadeIn">
                    <div className="fbox-icon">
                    </div>
                    <h3>1986</h3>
                    <p className="text-center">Création de la Société EUROLABOR, Entreprise de Travail Temporaire, 74 rue de Maubeuge – 75009 PARIS
                      Secteurs d’activité à l’origine : Modelage, Plasturgie et Mécanique Générale.</p>
                  </div>

                  <div className="feature-box fbox-right topmargin fadeIn animated" data-animate="fadeIn"
                       data-delay="200">
                    <div className="fbox-icon">
                    </div>
                    <h3>1991</h3>
                    <p className="text-center">Création de la Société Holding MOCHINO SA.</p>
                  </div>

                  <div className="feature-box fbox-right topmargin fadeIn animated" data-animate="fadeIn"
                       data-delay="400">
                    <div className="fbox-icon">
                    </div>
                    <h3>2003</h3>
                    <p className="text-center">La Société EUROLABOR crée l’enseigne commerciale « Génération Intérim ».</p>
                  </div>

                </div>

                <div className="col-lg-4 d-md-none d-lg-block bottommargin center">
                  <img src={serviceA} alt="" />
                  <img className="topmargin" src={serviceF} alt="" />
                </div>

                <div className="col-lg-4 col-md-6 bottommargin">

                  <div className="feature-box topmargin fadeIn animated" data-animate="fadeIn">
                    <div className="fbox-icon">
                    </div>
                    <h3>2004</h3>
                    <p className="text-center">Ouverture de l’Agence MODORIS dans l’Oise (Plessis Belleville) Secteur d’activité : Modelage, Plasturgie.</p>
                  </div>

                  <div className="feature-box topmargin fadeIn animated" data-animate="fadeIn" data-delay="200">
                    <div className="fbox-icon">
                    </div>
                    <h3>2006</h3>
                    <p className="text-center">Ouverture de l’Agence GENERATION INTERIM en Guadeloupe du Groupe EUROLABOR.</p>
                  </div>

                  <div className="feature-box topmargin fadeIn animated" data-animate="fadeIn" data-delay="400">
                    <div className="fbox-icon">
                    </div>
                    <h3>2013</h3>
                    <p className="text-center">Création de la SASU « Génération Intérim Guadeloupe ».</p>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <section id="content" style={{marginBottom: "0px"}}>
            <div className="container clearfix">

              <div className="heading-block topmargin-sm center">
                <h3>Rencontrez notre équipe</h3>
              </div>

              {/*<div className="row">
                <div className="bottommargin">
                  <img src={team} alt=""/>
                </div>
              </div>*/}

              <div className="row">
                <div className="col-lg-3 col-md-6 bottommargin">

                  <div className="team">
                    <div className="team-desc team-desc-bg">
                      <div className="team-title"><h4>Jean Claude Felicie</h4></div>
                      <div className="team-content">
                        Gérant de Génération Intérim
                      </div>
                    </div>
                  </div>

                </div>

                <div className="clear"></div>

                <div className="col-lg-3 col-md-6 bottommargin">

                  <div className="team">
                    <div className="team-desc team-desc-bg">
                      <div className="team-title"><h4>Marie-Line PISTOL</h4></div>
                      <div className="team-content">
                        <h5>Service administratif</h5>
                        Directrice d'affaires financières
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-3 col-md-6 bottommargin">

                  <div className="team">
                    <div className="team-desc team-desc-bg">
                      <div className="team-title"><h4>Line Baladine</h4></div>
                      <div className="team-content">
                        <h5>Service administratif</h5>
                        Assistante de Direction
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-3 col-md-6 bottommargin">

                  <div className="team">
                    <div className="team-desc team-desc-bg">
                      <div className="team-title"><h4>Ananda Ponin</h4></div>
                      <div className="team-content">
                        <h5>Service administratif</h5>
                        Assistante de Gestion
                      </div>
                    </div>
                  </div>

                </div>

                <div className="clear"></div>

                <div className="heading-block" style={{marginLeft: "auto", marginRight: "auto"}}>
                  <h4>GENERATION INTERIM GUADELOUPE</h4>
                </div>

                <div className="clear"></div>

                <div className="col-lg-3 col-md-6 bottommargin">
                  <div className="team">
                    <div className="team-desc team-desc-bg">
                      <div className="team-title"><h4>Sylvia Ramssamy</h4></div>
                      <div className="team-content">
                        <h5>Service commercial</h5>
                        Assistante commercial
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-3 col-md-6 bottommargin">

                  <div className="team">
                    <div className="team-desc team-desc-bg">
                      <div className="team-title"><h4>Brigitte Turlotin</h4></div>
                      <div className="team-content">
                        <h5>Service commercial</h5>
                        Technico Commerciale Intérim CDD CDI
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-3 col-md-6 bottommargin">

                  <div className="team">
                    <div className="team-desc team-desc-bg">
                      <div className="team-title"><h4>Olivia Laurent</h4></div>
                      <div className="team-content">
                        <h5>Service commercial</h5>
                        Technico Commerciale Intérim CDD CDI
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-3 col-md-6 bottommargin">

                  <div className="team">
                    <div className="team-desc team-desc-bg">
                      <div className="team-title"><h4>Nelly Labry</h4></div>
                      <div className="team-content">
                        <h5>Service commercial</h5>
                        Technico Commerciale Intérim CDD CDI
                      </div>
                    </div>
                  </div>
                </div>

                <div className="heading-block" style={{marginLeft: "auto", marginRight: "auto"}}>
                  <h4>GENERATION INTERIM MARTINIQUE</h4>
                </div>

                <div className="clear"></div>

                <div className="col-lg-3 col-md-6 bottommargin">
                  <div className="team">
                    <div className="team-desc team-desc-bg">
                      <div className="team-title"><h4>Guillaume Durantet</h4></div>
                      <div className="team-content">
                        <h5>Service commercial</h5>
                        Technico Commerciale Intérim CDD CDI
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    );
  }
}

export default WhoWeAre;
