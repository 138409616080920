import React from 'react';
import footerLogo from './../images/generation-interim/LOGO_GENERATION_INTERIM.png';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer id="footer" className="dark">
            <div id="copyrights">
                <div className="container clearfix">
                    <div className="col_half">
                        <img src={footerLogo} alt="Footer Logo" className="footer-logo"/>
                        Copyrights © 2014 All Rights Reserved by Generation Interim.
                    </div>
                    <div className="col_half col_last tright">
                        <div className="copyrights-menu copyright-links fright clearfix">
                            <Link to="/">Accueil</Link>/<Link to="/">Offres d'emploi</Link>/<Link to="/qui-sommes-nous">
                            Qui sommes nous ?</Link>/<Link to="/candidature">Candidature</Link>
                        </div>
                        <div className="fright clearfix">
                            <a href="#" className="social-icon si-small si-borderless nobottommargin si-facebook">
                                <i className="icon-facebook"></i>
                                <i className="icon-facebook"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless nobottommargin si-twitter">
                                <i className="icon-twitter"></i>
                                <i className="icon-twitter"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless nobottommargin si-gplus">
                                <i className="icon-gplus"></i>
                                <i className="icon-gplus"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless nobottommargin si-pinterest">
                                <i className="icon-pinterest"></i>
                                <i className="icon-pinterest"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless nobottommargin si-vimeo">
                                <i className="icon-vimeo"></i>
                                <i className="icon-vimeo"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless nobottommargin si-github">
                                <i className="icon-github"></i>
                                <i className="icon-github"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless nobottommargin si-yahoo">
                                <i className="icon-yahoo"></i>
                                <i className="icon-yahoo"></i>
                            </a>

                            <a href="#" className="social-icon si-small si-borderless nobottommargin si-linkedin">
                                <i className="icon-linkedin"></i>
                                <i className="icon-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;