class ValidateForm {

    constructor() {
        this.errors = {};
    }

    validateCandidature = (data) => {

        const {
            name,
            firstname,
            phone,
            email,
            jobFunction,
            file
        } = data;

        if (!this.validateName(name)) {
            return false;
        }

        if (!this.validateFirstname(firstname)) {
            return false;
        }

        if (!this.validatePhone(phone)) {
            return false;
        }

        if (!this.validateJobFunction(jobFunction)) {
            return false;
        }

        if (!this.validateFile(file)) {
            return false;
        }

        return this.validateEmail(email);

    };

    validateName = (name) => {
        if (typeof name === "undefined") {
            this.errors.name = { error: { message: "Le nom ne peut pas etre vide"}};
            return false;
        }
        if (name === "") {
            this.errors.name = { error: { message: "Le nom ne peut pas etre vide"}};
            return false
        }

        return true;
    };

    validateFirstname = (firstname) => {
        if (typeof firstname === "undefined") {
            this.errors.firstname = { error: { message: "Le prenom ne peut pas etre vide"}};
            return false;
        }
        if (firstname === "") {
            this.errors.firstname = { error: { message: "Le prenom ne peut pas etre vide"}};
            return false
        }

        return true;
    };

    validatePhone = (phone) => {
        if (typeof phone === "undefined") {
            this.errors.phone = { error: { message: "Le numéro de téléphone ne peut pas etre vide"}};
            return false;
        }
        if (phone === "") {
            this.errors.phone = { error: { message: "Le numéro de téléphone ne peut pas etre vide"}};
            return false
        }

        if (phone.match(/\d+/) === null) {
            this.errors.phone = { error: { message: "Le numéro de téléphone doit comporter des chiffres"}};
            return false
        }

        if (phone.match(/\d+/)[0].length < 10) {
            this.errors.phone = { error: { message: "Le numéro de téléphone doit comporter au moins 10 chiffres"}};
            return false
        }

        return true;
    };

    validateEmail = (email) => {
        if (typeof email === "undefined") {
            this.errors.email = { error: { message: "l'email ne peut pas etre vide"}};
            return false;
        }
        if (email === "") {
            this.errors.email = { error: { message: "l'email ne peut pas etre vide"}};
            return false
        }

        if (email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) === null) {
            this.errors.email = { error: { message: "l'email n'est pas valide"}};
            return false;
        }

        return true;
    };

    validateJobFunction = (jobFunction) => {
        if (typeof jobFunction === "undefined") {
            this.errors.jobFunction = { error: { message: "la fonction ne peut pas etre vide"}};
            return false;
        }
        if (jobFunction === "") {
            this.errors.jobFunction = { error: { message: "la fonction ne peut pas etre vide"}};
            return false;
        }

        return true;
    };

    validateFile = (file) => {
        if (typeof file === "undefined") {
            this.errors.file = { error: { message: "le cv doit etre joint"}};
            return false;
        }
        if (!(file instanceof File)) {
            this.errors.file = { error: { message: "le cv doit etre joint"}};
            return false;
        }

        return true;
    }
}

export default ValidateForm;