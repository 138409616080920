import Home from "../Home/Home";
import JobOffers from "../JobOffer/JobOffers";
import JobOffer from "../JobOffer/JobOffer";
import WhoWeAre from "../WhoWeAre/WhoWeAre";
import Candidature from "../Contact/Candidature";
import Client from "../Client/Client";
import {PrivateRoute} from "./PrivateRoute";
import Admin from "../Admin/Admin";
import React from "react";
import Login from "./Login";
import { Route, Switch } from 'react-router-dom';
import NotFound from "./NotFound";


const Routes = () => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/offres-emploi" component={JobOffers} />
        <Route exact path="/offre-emploi/:id" component={JobOffer} />
        <Route exact path="/qui-sommes-nous" component={WhoWeAre} />
        <Route exact path="/candidature" component={Candidature} />
        <Route exact path="/client" component={Client} />
        <Route exact path="/admin/login" component={Login} />
        <PrivateRoute exact path="/admin" component={Admin} />
        <Route path="*" component={NotFound} />
    </Switch>
);

export default Routes;