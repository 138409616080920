import {API} from "../config";
import axios from 'axios';

export const signin = (data) => {
    return axios.post(`${API}/signin`, data)
        .then((response) => {
            return response;
        }).catch((e) => {
            console.log(e);
        })
};

export const loadJobOfferList = (limit, skip) => {
    return axios.get(`${API}/job/offer/list`, { params: {limit: limit, skip: skip}})
        .then((response) => {
            return response;
        }).catch(e => {
            console.log(e);
        })
};

export const getCategories = () => {
    return axios.get(`${API}/job/offer/categories`)
        .then((response) => {
            return response;
        }).catch(e => {
            console.log(e);
        })
};

export const loadJobOffer = (jobOfferId) => {
    return axios.get(`${API}/job/offer/${jobOfferId}`)
        .then((response) => {
            return response;
        }).catch(e => {
            console.log(e);
        })
};

export const addCandidature = (formData) => {
    return axios.post(`${API}/candidature/add`, formData)
        .then((response) => {
            return response
        }).catch(e => {
            console.log(e);
        });
};

export const isAuthenticate = () => {
    if (typeof window == 'undefined') {
        return false;
    }

    if (localStorage.getItem('authToken')) {
        return JSON.parse(localStorage.getItem('authToken'));
    } else {
        return false;
    }
};

export const logout = (next) => {
    if (typeof window !== "undefined") {
        localStorage.removeItem("authToken");
        next();

        return fetch(`${API}/logout`,{
            method:"GET",
        }).then( response => {
            console.log("logout", response);
        }).catch(err => console.log(err));
    }
};
